import React, { useEffect, useState } from 'react';
import './ScrollDown.css';
import { useLocation } from 'react-router-dom';



const ScrollDown = ({nextSection, locomotiveScrollRef }) => {
  const [scrollText, setScrollText] = useState('SCROLL DOWN');
  const [isPageLongEnough, setIsPageLongEnough] = useState(false);
  const location = useLocation();

// if (nextSection===null){
//   nextSection="top";
// }
  useEffect(() => {
    if (nextSection) {
      setScrollText(nextSection === "top" ? 'SCROLL TO TOP' : `SCROLL TO ${nextSection.toUpperCase()} SECTION`);
    } else {
      setScrollText('SCROLL DOWN');
    }
  }, [nextSection]);
  // console.log("🚀 ~ handleScroll ~ locomotiveScrollRef.current:", locomotiveScrollRef)

  const handleScroll = () => {
    if (nextSection === "top") {
      if (locomotiveScrollRef.current) {
        locomotiveScrollRef.current.scrollTo(0, {
          duration: 1000,
          offset: 0,
          easing: [0.25, 0.00, 0.35, 1.00],
        });
      }
    } else {
      const element = document.querySelector(`[data-section="${nextSection}"]`);
      if (element && locomotiveScrollRef.current) {
        locomotiveScrollRef.current.scrollTo(element, {
          duration: 1000,
          offset: 0,
          easing: [0.25, 0.00, 0.35, 1.00],
        });
      }
    }
  };


  useEffect(() => {
    const checkPageHeight = () => {
      const pageHeight = document.documentElement.scrollHeight;
      const viewportHeight = window.innerHeight;
      setIsPageLongEnough(pageHeight > viewportHeight);
    };

    checkPageHeight();
    window.addEventListener('resize', checkPageHeight);

    return () => {
      window.removeEventListener('resize', checkPageHeight);
    };
  }, [location.pathname]);
  if (!isPageLongEnough) {
    return null; // Do not render the button if the page height is not long enough
  }


  return (
    <div>
      <div className="">
        <div className="framer-2ziyqt-container hidden-v0htzi">
          <div
            className="framer-PEzhK framer-1ofjumu framer-v-1ofjumu hover framer-1h763lh"
            onClick={(e) => {
              e.preventDefault(); 
              handleScroll();
            }}
            tabIndex="0"
            style={{ transform: 'none', opacity: '1', position:"relative" }}
            data-framer-name="Hero"
          >
            <div
              className="framer-cprvjm-container"
              data-framer-name="TEXT"
              name="TEXT"
              style={{  transformOrigin: '50% 50% 0px', transition: 'transform 0.5s ease' }}
            >
              <div draggable="false" style={{ width: '100%', height: '100%', position: 'relative' }}>
                <svg
                  className="transform-origin-center-center"
                  viewBox="0 0 100 100"
                  overflow="visible"
                  style={{
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    inset: '0px',
                    transformOrigin: 'center center',
                    willChange: 'transform',
                  }}
                >
                  <path
                    id="curve-wnxkz4"
                    d="M 0 50 L 0 50 A 1 1 0 0 1 100 50 L 100 50 L 100 50 A 1 1 0 0 1 0 50 L 0 50"
                    strokeWidth="none"
                    fill="transparent"
                  ></path>
                  <text>
                    <textPath
                      href="#curve-wnxkz4"
                      startOffset="0"
                      dominantBaseline="Hanging"
                      style={{
                        fontSize: '10px',
                        fontFamily: 'anton',
                        fontWeight: '400',
                        wordSpacing: '6px',
                        letterSpacing: '1.7px',
                        fill: 'var(--token-63cd80b7-7dd5-4e48-9fe1-a43360510b7f, rgb(251, 255, 219))',
                      }}
                    >
                      ✦ {scrollText} ✦ {scrollText} ✦ {scrollText}
                    </textPath>
                  </text>
                </svg>
              </div>
            </div>
            <div
              className="framer-1475qda"
              data-border="true"
              style={{
                '--border-bottom-width': '1px',
                '--border-color': 'var(--token-084bbccc-5c21-43ee-baa5-4b6a5d00f9c8, rgb(75, 105, 100))',
                '--border-left-width': '1px',
                '--border-right-width': '1px',
                '--border-style': 'solid',
                '--border-top-width': '1px',
                // backgroundColor: 'var(--token-00080319-2e38-4301-8012-c588d052bfb3, rgb(24, 41, 40))',
                // background: 'linear-gradient(119deg, rgb(0 19 93) 0%,  #0b3cff 100%)',
                background: 'linear-gradient(119deg, rgba(14, 3, 30, 0.918) -40%, rgb(0, 31, 153) 100%)',
                borderRadius: '160px',
                transform: 'translate(-50%, -50%)',
              }}
            ></div>
            <div
              className="framer-pizd6a"
              data-border="true"
              style={{
                '--border-bottom-width': '1px',
                '--border-color': 'var(--token-084bbccc-5c21-43ee-baa5-4b6a5d00f9c8, rgb(75, 105, 100))',
                '--border-left-width': '1px',
                '--border-right-width': '1px',
                '--border-style': 'solid',
                '--border-top-width': '1px',
                backgroundColor: 'rgba(0, 0, 0, 0)',
                borderRadius: '90px',
                opacity: '1',
              }}
            >
              <div
                data-framer-component-type="SVG"
                data-framer-name="arrow_forward_FILL0_wght400_GRAD0_opsz24_1_"
                className="framer-1gqhdmb"
                style={{
                  imageRendering: 'pixelated',
                  flexShrink: '0',
                  opacity: '1',
                  backgroundSize: '100% 100%',
                  backgroundImage: `url("data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20viewBox%3D%220%200%2054%2054%22%3E%3Cpath%20d%3D%22M%2021.306%2015.75%20L%200%2015.75%20L%200%2012.25%20L%2021.306%2012.25%20L%2011.506%202.45%20L%2014%200%20L%2028%2014%20L%2014%2028%20L%2011.506%2025.55%20Z%22%20transform%3D%22translate(13%2013)%20rotate(135%2014%2014)%22%20fill%3D%22rgb(255%2C%20255%2C%20255)%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E")`,
                  rotate: `${nextSection === "top" ? '135deg' : ''}`,
                }}
              ></div>
            </div>
            <div
              className="framer-1e8d3zx"
              data-framer-name="Hover-BG"
              style={{
                backgroundColor: 'rgb(255, 255, 255)',
                borderRadius: '16000%',
                transform: 'translate(-50%, -50%) scale(0)',
                transformOrigin: '50% 50% 0px',
              }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScrollDown;
