import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './styles/components.css';
import './styles/style-new.css';
import './styles/normalize.css';
// import './styles/styleguide.css';
import './styles/locomotive-scroll.css';
import { AudioProvider } from './context/AudioContext';
import ReactGA from 'react-ga4';

// Replace with your GA4 Measurement ID
ReactGA.initialize('G-K5QVGH2SXN');

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <AudioProvider>
      <App />
    </AudioProvider>
  </React.StrictMode>
);

// Track initial page load
// ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
