import React from 'react';
import './FallingStars.css';

const getRandomValue = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;

const FallingStars = ({ count = 50, tailLengthRange = [500, 750], durationRange = [6000, 12000], delayRange = [0, 10000], bg }) => {
    const stars = Array.from({ length: count }).map((_, index) => {
        const tailLength = getRandomValue(...tailLengthRange) / 100; // Convert to em units
        const topOffset = `${getRandomValue(0, 100)}vh`;
        const fallDuration = `${getRandomValue(...durationRange) / 1000}s`;
        const fallDelay = `${getRandomValue(...delayRange) / 1000}s`;
    
        const starWidth = tailLength / 6 + 'em'; // Calculate width based on tail length
    
        const starStyles = {
          position: 'absolute',
          width: `${tailLength}em`,
          height: '2px',
          top: topOffset,
          left: '0',
          background: 'linear-gradient(45deg, white, transparent)',
          borderRadius: '50%',
          filter: 'drop-shadow(0 0 6px white)',
          transform: 'translate3d(104em, 0, 0)',
          animation: `fall ${fallDuration} ${fallDelay} linear infinite, tail-fade ${fallDuration} ${fallDelay} ease-out infinite`
        };
    
        const beforeAfterStyles = {
          position: 'absolute',
          content: '""',
          top: '0',
          left: `calc(${starWidth} / -2)`,
          width: starWidth,
          height: '100%',
          background: 'linear-gradient(45deg, transparent, white, transparent)',
          borderRadius: 'inherit',
          animation: 'blink 2s linear infinite'
        };
    
        return (
          <div key={index} className="star" 
          style={{display:"none"}}
          // style={starStyles}
          >
            <span style={{...beforeAfterStyles, transform: 'rotate(45deg)'}}></span>
            <span style={{...beforeAfterStyles, transform: 'rotate(-45deg)'}}></span>
          </div>
        );
    });

    return <div style={{backgroundColor:bg}} className="stars">{stars}</div>;
}

export default FallingStars;
