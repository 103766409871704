import React, { useState } from "react";
import { Link, useLocation } from 'react-router-dom';
import "./Sidebar.css";

const SideBar = ({ toggleNav }) => {
  const [isActive, setIsActive] = useState(false);
  const location = useLocation(); 

  const toggleActive = () => {
    setIsActive((prev) => !prev);
    toggleNav();
  };

  const handleClick = () => {
    toggleActive();
    if (typeof window.playAudio === 'function') {
      window.playAudio('click-audio');
    } else {
      console.error('playAudio function is not defined.');
    }
  };

  const handleMouseEnter = () => {
    if (typeof window.playAudio === 'function') {
      window.playAudio('background-audio');
    } else {
      console.error('playAudio function is not defined.');
    }
  };

  const navLinks = [
    { text: 'Home', to: '/' },
    { text: 'Work', to: '/work' },
    { text: 'About', to: '/about' },
    { text: 'Contact', to: '/contact' },
  ];

  const socialLinks = [
    { text: 'Github', to: 'https://github.com/Imam-Faheem' },
    { text: 'LinkedIn', to: 'https://www.linkedin.com/in/imamfaheem' },
    { text: 'Instagram', to: 'https://www.instagram.com/imam__faheem/' },
    { text: 'X', to: 'https://x.com/imam__faheem' },
  ];
  
  return (
    <div>
      <div className={`btn btn-hamburger ${isActive ? "active" : ""}`}>
        <div
          className="btn-click magnetic"
          data-strength="50"
          data-strength-text="25"
          onClick={toggleActive}
        >
          <div className="btn-fill"></div>
          <div className="btn-text">
            <div className="btn-bars"></div>
            <span className="btn-text-inner">Menu</span>
          </div>
        </div>
      </div>

      <div onClick={toggleActive} className="overlay fixed-nav-back"></div>

      <div className="fixed-nav theme-dark">
        <div className="fixed-nav-rounded-div">
          <div className="rounded-div-wrap">
            <div className="rounded-div"></div>
          </div>
        </div>
        <div className="fixed-nav-inner">
          <div className="row nav-row">
            <h5>Navigation</h5>
            <div className="stripe"></div>
            <ul className="links-wrap">
              {navLinks.map((link, index) => (
                <li key={index} className={`btn btn-link ${location.pathname === link.to ? 'active' : ''}`}>
                  <Link
                    onMouseEnter={handleMouseEnter}
                    onClick={handleClick}
                    rel="noreferrer"
                    to={link.to}
                    className="btn-click magnetic"
                    data-strength="24"
                    data-strength-text="12"
                  >
                    <span className="btn-text">
                      <span className="btn-text-inner">{link.text}</span>
                    </span>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="row social-row">
            <div className="stripe"></div>
            <div className="socials">
              <h5>Socials</h5>
              <ul>
                {socialLinks.map((link, index) => (
                  <li key={index} className="btn btn-link btn-link-external">
                    <Link
                      onMouseEnter={handleMouseEnter}
                      onClick={handleClick}
                      rel="noreferrer"
                      to={link.to}
                      target="_blank"
                      className="btn-click magnetic"
                      data-strength="20"
                      data-strength-text="10"
                    >
                      <span className="btn-text">
                        <span className="btn-text-inner">{link.text}</span>
                      </span>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
