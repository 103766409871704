import React, { Suspense, useEffect, useState,  } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Analytics } from '@vercel/analytics/react';
import LoadingSpinner from './components/common/LoadingSpinner';
import ErrorBoundary from './components/common/ErrorBoundary';
import './styles/components.css';
import './styles/style-new.css';
import './styles/styleguide.css';
import './styles/normalize.css';
import './styles/locomotive-scroll.css';
import './animations.css';
import SideBar from './components/layout/SideBar';
import './App.css';
import CustomCursor from './components/common/CustomCurser';
import ScrollDown from './components/common/ScrollDown';
import BgMusicBtn from './components/common/BgMusicBtn';
import PageLoader from './components/common/PageLoader';
import WelcomeModal from './components/layout/WelcomeModal';
const Home = React.lazy(() => import('./pages/Home'));
const Work = React.lazy(() => import('./pages/Work'));
const WorkDetails = React.lazy(() => import('./pages/WorkDetails'));
const About = React.lazy(() => import('./pages/About'));
const Contact = React.lazy(() => import('./pages/Contact'));
const NotFound = React.lazy(() => import('./pages/NotFound'));



const App = () => {

  const [showModal, setShowModal] = useState(false);
  const [scrolled, setScrolled] = useState(true);
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [currentSection, setCurrentSection] = useState('');
  const [nextSection, setNextSection] = useState('');
  const [locomotiveScrollRef, setLocomotiveScrollRef] = useState(null);
  const [isLaptop, setIsLaptop] = useState(window.innerWidth >= 1024);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2500); 
  }, []);


  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  }


   useEffect(() => {
    const hasVisited = localStorage.getItem('hasVisited');
    if (!hasVisited) {
      setShowModal(true);
      localStorage.setItem('hasVisited', 'true');
    }
  }, []);

  const handleClose = () => {
    setShowModal(false);
  };


  useEffect(() => {
    const handleResize = () => {
      setIsLaptop(window.innerWidth >= 1024);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  
  
  return (
    <BrowserRouter>
    {/* <ThemeProvider> */}
    {/* <AudioProvider> */}
        {/* <GlobalStyles /> */}
        <Suspense 
        // fallback={
        // <div style={{height:"100vh", width:"100%", display:"flex", justifyContent:"center", alignItems:"center", color:"white"}}>
        //   <h1>loading...</h1>
        // </div>
        // }
        >
          <ErrorBoundary>
    <Analytics />
          <main style={{position:"relative", width:"100%"}} className={`main no-touch ${scrolled ? "scrolled" : "" } ${isNavOpen ? "nav-active":""} `} id="home" data-barba="container" data-barba-namespace="home">
          {isLaptop && <CustomCursor />}
            <div className={`scroll-down-container ${!isNavOpen ? 'visible' : 'hide'}`}>
              <ScrollDown 
              nextSection={nextSection} locomotiveScrollRef={locomotiveScrollRef}
              // currentSection={currentSection}
              />
            </div>
            {!isLoading && <WelcomeModal show={showModal} onClose={handleClose} />}
            <PageLoader/>
            {/* <BgPlayer/> */}
            <BgMusicBtn/>
            <SideBar toggleNav={toggleNav}/>
            <Routes>
              <Route exact path="/" element={<Home isNavOpen={isNavOpen} setCurrentSection={setCurrentSection} setNextSection={setNextSection} nextSection={nextSection} setLocomotiveScrollRef={setLocomotiveScrollRef}/>}/>
              <Route path="/work" element={<Work setCurrentSection={setCurrentSection} setNextSection={setNextSection}  setLocomotiveScrollRef={setLocomotiveScrollRef}/>} />
              <Route path="/work/:workId" element={<WorkDetails setCurrentSection={setCurrentSection} setNextSection={setNextSection}  setLocomotiveScrollRef={setLocomotiveScrollRef}/>} />

              <Route path="/about" element={<About setCurrentSection={setCurrentSection} setNextSection={setNextSection}  setLocomotiveScrollRef={setLocomotiveScrollRef}/>} />
              <Route path="/contact" element={<Contact/>} />
              <Route path="/loading" element={<LoadingSpinner pageName={"Hellow"} show={true}/>} />
              <Route element={<NotFound/>} />
            </Routes>
            
          </main>
          
          </ErrorBoundary>
        </Suspense>
    {/* </ThemeProvider> */}
      </BrowserRouter>
  );
};

export default App;
