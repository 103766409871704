import React, { createContext, useContext } from 'react';
import useAudioPlayback from '../components/hooks/useAudioPlayback';

const AudioContext = createContext();

export const AudioProvider = ({ children }) => {
  const [playAudio] = useAudioPlayback('background-audio');
  const [playClickAudio] = useAudioPlayback('click-audio');

  return (
    <AudioContext.Provider value={{ playAudio, playClickAudio }}>
      {children}
    </AudioContext.Provider>
  );
};

export const useAudio = () => useContext(AudioContext);

















// // AudioContext.js
// import React, { createContext, useContext } from 'react';
// import useAudioPlayback from '../components/hooks/useAudioPlayback';

// const AudioContext = createContext();

// export const AudioProvider = ({ children }) => {
//   const [playAudio, AudioPlayer] = useAudioPlayback('/assets/audios/All-Buttons-click-v2.mp3', false);
//   const [playClickAudio, ClickAudioPlayer] = useAudioPlayback('/assets/audios/OnClick Swoosh Effect.wav', false);

//   return (
//     <AudioContext.Provider value={{playAudio, playClickAudio}}>
//       {children}
//       <ClickAudioPlayer />
//       <AudioPlayer />
//     </AudioContext.Provider>
//   );
// };

// export const useAudio = () => useContext(AudioContext);
