import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import LoadingSpinner from './LoadingSpinner';

const PageLoader = ({ children }) => {
  const location = useLocation();
  const [pageName, setPageName] = useState('');
  const [key, setKey] = useState(0);

  const getPageName = (path) => {
    switch (path) {
      case '/':
        return 'Home';
      case '/work':
        return 'Work';
      case '/about':
        return 'About';
      case '/contact':
        return 'Contact';
      default:
        if (path.startsWith('/work/')) {
          const name = path.replace('/work/', '');
          const formattedName = name.split('-')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
          return formattedName;
        }
        return 'Page';
    }
  };

  useEffect(() => {
    setPageName(getPageName(location.pathname));
    setKey(prevKey => prevKey + 1); // Force a re-render
  }, [location.pathname]);

  return (
    <>
      <LoadingSpinner key={key} pageName={pageName} show={true} />
      {children}
    </>
  );
};

export default PageLoader;
