import React, { Component } from 'react';
class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    // Log error to an error reporting service
    console.error('Error caught by ErrorBoundary:', error, errorInfo);
    // You can also send error details to a logging service or analytics platform here
    // Example: logErrorToServer(error, errorInfo);
  }

  reloadPage = () => {
    window.location.reload();
  };

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div style={{display:"flex", justifyContent:"center", alignItems:"center", height:"100vh"}}>
          <div>
            <h1 style={{color:"white"}}>Something went wrong.</h1>
            <p>We apologize for the inconvenience. Please try again later.</p>
            <button onClick={this.reloadPage}>Refresh Page</button>
          </div>
        </div>
      );
    }

    return this.props.children; 
  }
}

export default ErrorBoundary;
