import { useRef, useState, useEffect } from 'react';

const useAudioPlayback = (audioId, loop = false) => {
  const audioRef = useRef(null);
  const [isAudioEnabled, setIsAudioEnabled] = useState(false);

  useEffect(() => {
    const enableAudio = () => {
      setIsAudioEnabled(true);
      document.removeEventListener('click', enableAudio);
    };

    document.addEventListener('click', enableAudio);

    return () => {
      document.removeEventListener('click', enableAudio);
    };
  }, []);

  useEffect(() => {
    audioRef.current = document.getElementById(audioId);
  }, [audioId]);

  const playAudio = () => {
    if (isAudioEnabled && audioRef.current) {
      audioRef.current.currentTime = 0; 
      audioRef.current.play().catch(error => {
        console.error('Autoplay was prevented:', error);
      });
    }
  };

  return [playAudio];
};

export default useAudioPlayback;





















// import { useRef, useState, useEffect } from 'react';

// const useAudioPlayback = (audioSrc, loop = false) => {
//   const audioRef = useRef(null);
//   const [isAudioEnabled, setIsAudioEnabled] = useState(false);


//   useEffect(() => {
//     const enableAudio = () => {
//       setIsAudioEnabled(true);
//       document.removeEventListener('click', enableAudio);
//     };

//     document.addEventListener('click', enableAudio);

//     return () => {
//       document.removeEventListener('click', enableAudio);
//     };
//   }, []);

//   const playAudio = () => {
//     if (isAudioEnabled && audioRef.current) {
//       audioRef.current.currentTime = 0; 
//       audioRef.current.play().catch(error => {
//         console.error('Autoplay was prevented:', error);
//       });
//     }
//   };

//   const AudioPlayer = () => (
//     <audio ref={audioRef} loop={loop}>
//       <source src={audioSrc} type="audio/mp3" />
//       Your browser does not support the audio element.
//     </audio>
//   ); 

//   return [playAudio, AudioPlayer];
// };

// export default useAudioPlayback;
