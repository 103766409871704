import React, { useState, useEffect, useCallback } from 'react';
import './BgMusic.css';

const BgMusicBtn = React.memo(() => {
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = React.useRef(new Audio('/assets/audios/bgMusic.mp3'));

  const toggleMusic = useCallback(() => {
    setIsPlaying(prev => !prev);
  }, []);

  useEffect(() => {
    const audio = audioRef.current;
    audio.loop = true; // Enable looping
    if (isPlaying) {
      audio.play().catch(error => {
        console.error('Error playing audio:', error);
      });
    } else {
      audio.pause();
    }

    return () => {
      audio.pause();
    };
  }, [isPlaying]);

  useEffect(() => {
    const enableAudio = () => {
      if (!isPlaying) {
        setIsPlaying(false);  // Keep it false until the user manually toggles it
      }
      document.removeEventListener('click', enableAudio);
    };

    document.addEventListener('click', enableAudio);

    return () => {
      document.removeEventListener('click', enableAudio);
    };
  }, [isPlaying]);

  return (
    <div className="music-wrap">
      <div className={`sound-icon ${isPlaying ? '' : 'off'}`} onClick={toggleMusic}>
        <div className="sound-waves wave1"></div>
        <div className="sound-waves wave2"></div>
        <div className="sound-waves wave3"></div>
        <div className="sound-waves-sub wave1-sub"></div>
        <div className="sound-waves-sub wave2-sub"></div>
        <div className="sound-waves-sub wave3-sub"></div>
        <div className="sound-waves-sub wave4-sub"></div>
        <div className="sound-waves-sub wave5-sub"></div>
        <div className={`sound-text ${isPlaying ? 'on' : 'off'}`}></div>
      </div>
    </div>
  );
});

export default BgMusicBtn;
