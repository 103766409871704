import React, { useEffect, useState } from 'react';
import './loadingPage.css'; // Import the CSS for styling
import { ReactComponent as Icon } from '../../assets/images/loader.svg';
import FallingStars from './FallingStars';
import { useLocation } from 'react-router-dom';


const LoadingSpinner = ({pageName, show}) => {
  const [displayOverlay, setDisplayOverlay] = useState(false);
  // const location = useLocation();
  // const getPageName = (path) => {
  //   switch (path) {
  //     case '/':
  //       return 'Home';
  //     case '/work':
  //       return 'Work';
  //     case '/about':
  //       return 'About';
  //     case '/contact':
  //       return 'Contact';
  //     case '/loading':
  //       return 'Loading';
  //     default:
  //       return 'Page';
  //   }
  // };

  // const pageName = getPageName(location.pathname);

  useEffect(() => {
    if (show) {
      setDisplayOverlay(true);
      const timer = setTimeout(() => {
        setDisplayOverlay(false);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [show]);
  return show ? (
    <>
   {displayOverlay && <div className='overlay'></div>}
   <div style={{display:`${show?"flex":"none"}`}} className="loading-page-container slide_up wait move-aoff-top">
        {/* <FallingStars count={20} tailLengthRange={[400, 800]} durationRange={[4000, 8000]} delayRange={[0, 5000]} bg={'transparent'}/> */}
        {/* <div className="blob blob_1"></div> */}
        {/* <Icon className="my-custom-class" /> */}
        <h1 style={{marginLeft:"auto", marginRight:"auto", textAlign:"center", color:"white", fontWeight:"bold", paddingTop:"10px", paddingBottom:"10px"}}>{pageName}</h1>

        {/* <div className="blob blob_2"></div> */}
        {/* <div className="blob blob_3"></div> */}
    </div>
    </>
  ): null;
};

export default LoadingSpinner;
